import { createApp, nextTick } from '/js/petite-vue/dist/petite-vue.es.js'
import { createAtom } from "/js/vendor/xoid.custom.js"
import { reactiveAtom } from "/js/reactiveAtom.js"
import { uniqueId } from "/js/utils.js"

// import { notifsApi } from '/js/api.js'

import { Toast } from '/js/vendor/bootstrap.custom.js'

const position = createAtom([1,1])
const pageVisible = createAtom(false)
const list = createAtom([],
	(atom) => ({
		add: (toast) => atom.update(s => [...s, toast]),
		remove: (id) => atom.update(s => s.filter(o => o.id !== id)),
		clear: () => atom.set([])
	})
)


/*
	формат уведомления
	{
		severity - важность: info, success, warning, danger
		title - заголовок уведомления
		body - текст уведомления
		timestamp - время события
		icon - картинка (в теле уведомления, перед тестом)
		iconId - иконка (перед заголовком)
		autohide - скрывать автоматически
		delay - время перед скрытием
	}

	enqueue возвращает функцию удаления уведомления
*/
function enqueue(obj) {
	// TODO: добавить группировку
	obj.id = uniqueId();
	let similar = list.value.find(o => o.title === obj.title)
	let needReplace = similar !== undefined

	list.actions.add(obj)

	nextTick(() => {
		let item = document.getElementById('common-notifications')?.querySelector('[data-id="'+ obj.id +'"]')

		if (needReplace) {
			list.actions.remove(similar.id)
		};

		if (item) {
			if (pageVisible.value) Toast.getOrCreateInstance(item).show();

			item.addEventListener('hidden.bs.toast', (e) => {
				setTimeout(() => {
					if (e.target) list.actions.remove(e.target.dataset.id);
				}, 10);
			}, { once: true })
		};
	})

	const remove = (id) => {
		let item = document.getElementById('common-notifications')?.querySelector('[data-id="'+ id +'"]')
		if (item) Toast.getOrCreateInstance(item).hide();
	}

	return { remove: () => remove(obj.id) }
}


const interval = (ms) => {
	let int = ms/1000

	if (Math.abs(int/60) < 1) return [Math.ceil(int), 'second']
	else if (Math.abs(int/3600) < 1) return [Math.ceil(int/60), 'minute']
	else if (Math.abs(int/3600/24) < 1) return [Math.ceil(int/3600), 'hour']
	else if (Math.abs(int/3600/24/30) < 1) return [Math.ceil(int/3600/24), 'day']
	else if (Math.abs(int/3600/24/365) < 1) return [Math.ceil(int/3600/24/30), 'month']
	else  return [Math.ceil(int/3600/24/365), 'year']
}

const rtf = new Intl.RelativeTimeFormat(document.documentElement.lang, { numeric: 'auto' });


/* Привязка данных */
const store = createAtom(read => ({
	list: read(list),
	position: read(position),
	pageVisible: read(pageVisible),
}))


const ToastItem = function(props) {
	return {
		props,
		get datetime() {
			if (this.props.timestamp) {
				let [ value, unit ] = interval(new Date(this.props.timestamp) - new Date())
				return rtf.format(value, unit)
			}
			return ''
		},
		get severityClass () {
			switch (props.severity) {
				case 'info':
					return 'text-bg-primary border-0'

				case 'success':
					return 'text-bg-success border-0'

				case 'warning':
					return 'text-bg-warning border-0'

				case 'danger':
					return 'text-bg-danger border-0'

				default:
					return ''
			}
		},
		$template: '#notif-block__item-tpl',
	}
}

createApp({
	ToastItem,
	store: reactiveAtom(store),

	get positionClass() {
		let pos = this.store.position
		let classes = ''

		if (pos[0] === 0) classes += 'start-0'
		if (pos[0] === .5) classes += 'start-50 translate-middle-x'
		if (pos[0] === 1) classes += 'end-0'

		if (pos[1] === 0) classes += ' top-0'
		if (pos[1] === .5) classes += 'top-50 translate-middle-y'
		if (pos[1] === 1) classes += ' bottom-0'

		return classes
	},

	mounted(root) {
		if (!document.hidden) pageVisible.set(true);

	/* 	notifsApi.get_active().then(data => {
			if (data?.length) {
				data.forEach(o => enqueue(o))
			}
		}) */

		enquire.register('screen and (max-width: 719.98px) and (pointer: coarse)', {
			match: () => {
				position.set([.5,1])
			},
			unmatch: () => {
				position.set([1,1])
			},
		})

		document.addEventListener("visibilitychange", () => {
			if (document.hidden) pageVisible.set(false)
			else pageVisible.set(true);

			if (document.hidden) {
				root.querySelectorAll('.toast').forEach(node => {
					Toast.getInstance(node)._config.autohide = false;
				})
			}
			else {
				root.querySelectorAll('.toast').forEach(node => {
					let item = Toast.getOrCreateInstance(node);
					if (item) {
						if (!node.matches('[data-bs-autohide="false"]')) item._config.autohide = true;
						item.show();
					}
				})
			}
		});
	},
	$delimiters: ['[[', ']]'],
})
.mount('#common-notifications')

export default { enqueue }
